/* eslint-disable import/max-dependencies */
import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from "firebase/firestore"
import { genericConverter, WithRef } from "hooks/firestore/FirestoreDocument"
import { Account } from "types/firestore/account"
import { B2BInvoice } from "types/firestore/b2bInvoice"
import Basket from "types/firestore/basket"
import { Broadcast } from "types/firestore/broadcast"
import { Bundle } from "types/firestore/bundle"
import { Category } from "types/firestore/category"
import { ClickAndCollectTemperatureLog } from "types/firestore/clickAndCollectTemperatureLog"
import DiscountCode from "types/firestore/discountCode"
import { DistributionOption } from "types/firestore/distributionOption"
import { GlobalStats } from "types/firestore/globalStats"
import { HistoricOrders } from "types/firestore/historicOrders"
import { Invoices } from "types/firestore/invoices"
import { Item } from "types/firestore/item"
import { MintOrder } from "types/firestore/mintOrder"
import { Offering } from "types/firestore/offering"
import { Option } from "types/firestore/option"
import { Order } from "types/firestore/order"
import { OrderPlan } from "types/firestore/orderPlan"
import { Parcel } from "types/firestore/parcel"
import PaymentSettings from "types/firestore/paymentSettings"
import { PenguinLockerOrder } from "types/firestore/penguinLockerOrder"
import PrivateUser from "types/firestore/privateUser"
import ProductGroup from "types/firestore/productGroup"
import { Purchase } from "types/firestore/purchase"
import Settings from "types/firestore/settings"
import Shop from "types/firestore/shop"
import ShortUrl from "types/firestore/shorturl"
import { ShopStat, Stat } from "types/firestore/stat"
import { Storefront } from "types/firestore/storefront"
import { Subscriber } from "types/firestore/subscriber"
import Success from "types/firestore/success"
import { TemperatureLog } from "types/firestore/temperatureLog"
import User from "types/firestore/user"
import { UserOrder } from "types/firestore/userOrder"

function getConvertedCollection<T extends DocumentData>(path: string) {
  const ref = collection(getFirestore(), path)
  return ref.withConverter(genericConverter<T>())
}

function getConvertedDocument<T extends DocumentData>(path: string) {
  const ref = doc(getFirestore(), path)
  return ref.withConverter(genericConverter<T>())
}

// ts-prune-ignore-next
export function getCategoriesRef(offeringId: string, storefrontId: string): CollectionReference<WithRef<Category>>
export function getCategoriesRef(
  offeringId: string | undefined,
  storefrontId: string | undefined
): undefined | CollectionReference<WithRef<Category>>
export function getCategoriesRef(offeringId: string | undefined, storefrontId: string | undefined) {
  return offeringId && storefrontId
    ? getConvertedCollection<Category>(`offerings/${offeringId}/storefronts/${storefrontId}/categories`)
    : undefined
}

// ts-prune-ignore-next
export function getCategoryRef(
  offeringId: string,
  storefrontId: string,
  categoryId: string
): DocumentReference<WithRef<Category>>
export function getCategoryRef(
  offeringId: string | undefined,
  storefrontId: string | undefined,
  categoryId: string | undefined
): undefined | DocumentReference<WithRef<Category>>
export function getCategoryRef(
  offeringId: string | undefined,
  storefrontId: string | undefined,
  categoryId: string | undefined
) {
  return offeringId && storefrontId && categoryId
    ? getConvertedDocument<Category>(`offerings/${offeringId}/storefronts/${storefrontId}/categories/${categoryId}`)
    : undefined
}

// ts-prune-ignore-next
export function getProductGroupsRef(
  offeringId: string,
  storefrontId: string
): CollectionReference<WithRef<ProductGroup>>
export function getProductGroupsRef(
  offeringId: string | undefined,
  storefrontId: string | undefined
): undefined | CollectionReference<WithRef<ProductGroup>>
export function getProductGroupsRef(offeringId: string | undefined, storefrontId: string | undefined) {
  return offeringId && storefrontId
    ? getConvertedCollection<ProductGroup>(`offerings/${offeringId}/storefronts/${storefrontId}/productGroups`)
    : undefined
}

// ts-prune-ignore-next
export function getProductGroupRef(
  offeringId: string,
  storefrontId: string,
  productGroupId: string
): DocumentReference<WithRef<ProductGroup>>
export function getProductGroupRef(
  offeringId: string | undefined,
  storefrontId: string | undefined,
  productGroupId: string | undefined
): undefined | DocumentReference<WithRef<ProductGroup>>
export function getProductGroupRef(
  offeringId: string | undefined,
  storefrontId: string | undefined,
  productGroupId: string | undefined
) {
  return offeringId && storefrontId && productGroupId
    ? getConvertedDocument<ProductGroup>(
        `offerings/${offeringId}/storefronts/${storefrontId}/productGroups/${productGroupId}`
      )
    : undefined
}

// ts-prune-ignore-next
export function getBundlesRef(offeringId: string): CollectionReference<WithRef<Bundle>>
export function getBundlesRef(offeringId: string | undefined): undefined | CollectionReference<WithRef<Bundle>>
export function getBundlesRef(offeringId: string | undefined) {
  return offeringId ? getConvertedCollection<Bundle>(`offerings/${offeringId}/bundles`) : undefined
}

// ts-prune-ignore-next
export function getBundleRef(offeringId: string, bundleId: string): DocumentReference<WithRef<Bundle>>
export function getBundleRef(
  offeringId: string | undefined,
  bundleId: string | undefined
): undefined | DocumentReference<WithRef<Bundle>>
export function getBundleRef(offeringId: string | undefined, bundleId: string | undefined) {
  return offeringId && bundleId
    ? getConvertedDocument<Bundle>(`offerings/${offeringId}/bundles/${bundleId}`)
    : undefined
}

export function getOptionsRef(offeringId: string): CollectionReference<WithRef<Option>>
export function getOptionsRef(offeringId: string | undefined): undefined | CollectionReference<WithRef<Option>>
export function getOptionsRef(offeringId: string | undefined) {
  return offeringId ? getConvertedCollection<Option>(`offerings/${offeringId}/options`) : undefined
}

// ts-prune-ignore-next
export function getOptionRef(offeringId: string, optionId: string): DocumentReference<WithRef<Option>>
export function getOptionRef(
  offeringId: string | undefined,
  optionId: string | undefined
): undefined | DocumentReference<WithRef<Option>>
export function getOptionRef(offeringId: string | undefined, optionId: string | undefined) {
  return offeringId && optionId
    ? getConvertedDocument<Option>(`offerings/${offeringId}/options/${optionId}`)
    : undefined
}

// ts-prune-ignore-next
export function getItemsRef(offeringId: string): CollectionReference<WithRef<Item>>
export function getItemsRef(offeringId: string | undefined): undefined | CollectionReference<WithRef<Item>>
export function getItemsRef(offeringId: string | undefined) {
  return offeringId ? getConvertedCollection<Item>(`offerings/${offeringId}/items`) : undefined
}

// ts-prune-ignore-next
export function getItemRef(offeringId: string, itemId: string): DocumentReference<WithRef<Item>>
export function getItemRef(
  offeringId: string | undefined,
  itemId: string | undefined
): undefined | DocumentReference<WithRef<Item>>
export function getItemRef(offeringId: string | undefined, itemId: string | undefined) {
  return offeringId && itemId ? getConvertedDocument<Item>(`offerings/${offeringId}/items/${itemId}`) : undefined
}

// ts-prune-ignore-next
export function getOfferingsRef() {
  return getConvertedCollection<Offering>(`offerings`)
}

// ts-prune-ignore-next
export function getOfferingRef(offeringId: string): DocumentReference<WithRef<Offering>>
export function getOfferingRef(offeringId: string | undefined): undefined | DocumentReference<WithRef<Offering>>
export function getOfferingRef(offeringId: string | undefined) {
  return offeringId ? getConvertedDocument<Offering>(`offerings/${offeringId}`) : undefined
}

// ts-prune-ignore-next
export function getStorefrontsRef(offeringId: string): CollectionReference<WithRef<Storefront>>
export function getStorefrontsRef(offeringId: string | undefined): undefined | CollectionReference<WithRef<Storefront>>
export function getStorefrontsRef(offeringId: string | undefined) {
  return offeringId ? getConvertedCollection<Storefront>(`offerings/${offeringId}/storefronts`) : undefined
}

// ts-prune-ignore-next
export function getStorefrontRef(offeringId: string, storefrontId: string): DocumentReference<WithRef<Storefront>>
export function getStorefrontRef(
  offeringId: string | undefined,
  storefrontId: string | undefined
): undefined | DocumentReference<WithRef<Storefront>>
export function getStorefrontRef(offeringId: string | undefined, storefrontId: string | undefined) {
  return offeringId && storefrontId
    ? getConvertedDocument<Storefront>(`offerings/${offeringId}/storefronts/${storefrontId}`)
    : undefined
}

// ts-prune-ignore-next
export function getStatsRef(shopId: string): CollectionReference<WithRef<Stat>>
export function getStatsRef(shopId: string | undefined): undefined | CollectionReference<WithRef<Stat>>
export function getStatsRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<Stat>(`restaurants/${shopId}/stats`) : undefined
}

// ts-prune-ignore-next
export function getStatRef(shopId: string, statId: string): DocumentReference<WithRef<Stat>>
export function getStatRef(
  shopId: string | undefined,
  statId: string | undefined
): undefined | DocumentReference<WithRef<Stat>>
export function getStatRef(shopId: string | undefined, statId: string | undefined) {
  return shopId && statId ? getConvertedDocument<Stat>(`restaurants/${shopId}/stats/${statId}`) : undefined
}

// ts-prune-ignore-next
export function getOrdersRef(shopId: string): CollectionReference<WithRef<Order>>
export function getOrdersRef(shopId: string | undefined): undefined | CollectionReference<WithRef<Order>>
export function getOrdersRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<Order>(`restaurants/${shopId}/orders`) : undefined
}

// ts-prune-ignore-next
export function getOrderRef(shopId: string, orderId: string): DocumentReference<WithRef<Order>>
export function getOrderRef(
  shopId: string | undefined,
  orderId: string | undefined
): undefined | DocumentReference<WithRef<Order>>
export function getOrderRef(shopId: string | undefined, orderId: string | undefined) {
  return shopId && orderId ? getConvertedDocument<Order>(`restaurants/${shopId}/orders/${orderId}`) : undefined
}

// ts-prune-ignore-next
export function getDistributionOptionsRef(shopId: string): CollectionReference<WithRef<DistributionOption>>
export function getDistributionOptionsRef(
  shopId: string | undefined
): undefined | CollectionReference<WithRef<DistributionOption>>
export function getDistributionOptionsRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<DistributionOption>(`restaurants/${shopId}/distributionOptions`) : undefined
}

// ts-prune-ignore-next
export function getDistributionOptionRef(
  shopId: string,
  distributionOptionId: string
): DocumentReference<WithRef<DistributionOption>>
export function getDistributionOptionRef(
  shopId: string | undefined,
  distributionOptionId: string | undefined
): undefined | DocumentReference<WithRef<DistributionOption>>
export function getDistributionOptionRef(shopId: string | undefined, distributionOptionId: string | undefined) {
  return shopId && distributionOptionId
    ? getConvertedDocument<DistributionOption>(`restaurants/${shopId}/distributionOptions/${distributionOptionId}`)
    : undefined
}
// ts-prune-ignore-next
export function getBasketsRef(shopId: string): CollectionReference<WithRef<Basket>>
export function getBasketsRef(shopId: string | undefined): undefined | CollectionReference<WithRef<Basket>>
export function getBasketsRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<Basket>(`restaurants/${shopId}/baskets`) : undefined
}

// ts-prune-ignore-next
export function getBasketRef(shopId: string, basketId: string): DocumentReference<WithRef<Basket>>
export function getBasketRef(
  shopId: string | undefined,
  basketId: string | undefined
): undefined | DocumentReference<WithRef<Basket>>
export function getBasketRef(shopId: string | undefined, basketId: string | undefined) {
  return shopId && basketId ? getConvertedDocument<Basket>(`restaurants/${shopId}/baskets/${basketId}`) : undefined
}

// ts-prune-ignore-next
export function getSubscribersRef(shopId: string): CollectionReference<WithRef<Subscriber>>
export function getSubscribersRef(shopId: string | undefined): undefined | CollectionReference<WithRef<Subscriber>>
export function getSubscribersRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<Subscriber>(`restaurants/${shopId}/subscribers`) : undefined
}

// ts-prune-ignore-next
export function getSubscriberRef(shopId: string, subscriberId: string): DocumentReference<WithRef<Subscriber>>
export function getSubscriberRef(
  shopId: string | undefined,
  subscriberId: string | undefined
): undefined | DocumentReference<WithRef<Subscriber>>
export function getSubscriberRef(shopId: string | undefined, subscriberId: string | undefined) {
  return shopId && subscriberId
    ? getConvertedDocument<Subscriber>(`restaurants/${shopId}/subscribers/${subscriberId}`)
    : undefined
}

// ts-prune-ignore-next
export function getPurchasesRef(shopId: string): CollectionReference<WithRef<Purchase>>
export function getPurchasesRef(shopId: string | undefined): undefined | CollectionReference<WithRef<Purchase>>
export function getPurchasesRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<Purchase>(`restaurants/${shopId}/data/account/purchases`) : undefined
}

// ts-prune-ignore-next
export function getPurchaseRef(shopId: string, purchaseId: string): DocumentReference<WithRef<Purchase>>
export function getPurchaseRef(
  shopId: string | undefined,
  purchaseId: string | undefined
): undefined | DocumentReference<WithRef<Purchase>>
export function getPurchaseRef(shopId: string | undefined, purchaseId: string | undefined) {
  return shopId && purchaseId
    ? getConvertedDocument<Purchase>(`restaurants/${shopId}/data/account/purchases/${purchaseId}`)
    : undefined
}

// ts-prune-ignore-next
export function getBroadcastsRef(shopId: string): CollectionReference<WithRef<Broadcast>>
export function getBroadcastsRef(shopId: string | undefined): undefined | CollectionReference<WithRef<Broadcast>>
export function getBroadcastsRef(shopId: string | undefined) {
  return shopId
    ? getConvertedCollection<Broadcast>(`restaurants/${shopId}/marketing/emailBroadcast/broadcasts`)
    : undefined
}

// ts-prune-ignore-next
export function getBroadcastRef(shopId: string, broadcastId: string): DocumentReference<WithRef<Broadcast>>
export function getBroadcastRef(
  shopId: string | undefined,
  broadcastId: string | undefined
): undefined | DocumentReference<WithRef<Broadcast>>
export function getBroadcastRef(shopId: string | undefined, broadcastId: string | undefined) {
  return shopId && broadcastId
    ? getConvertedDocument<Broadcast>(`restaurants/${shopId}/marketing/emailBroadcast/broadcasts/${broadcastId}`)
    : undefined
}

// ts-prune-ignore-next
export function getShopsRef() {
  return getConvertedCollection<Shop>(`restaurants`)
}

// ts-prune-ignore-next
export function getSettingsRef(shopId: string): DocumentReference<WithRef<Settings>>
export function getSettingsRef(shopId: string | undefined): undefined | DocumentReference<WithRef<Settings>>
export function getSettingsRef(shopId: string | undefined) {
  return shopId ? getConvertedDocument<Settings>(`restaurants/${shopId}/data/settings`) : undefined
}

// ts-prune-ignore-next
export function getAccountRef(shopId: string): DocumentReference<WithRef<Account>>
export function getAccountRef(shopId: string | undefined): undefined | DocumentReference<WithRef<Account>>
export function getAccountRef(shopId: string | undefined) {
  return shopId ? getConvertedDocument<Account>(`restaurants/${shopId}/data/account`) : undefined
}

// ts-prune-ignore-next
export function getInvoicesRef(shopId: string): DocumentReference<WithRef<Invoices>>
export function getInvoicesRef(shopId: string | undefined): undefined | DocumentReference<WithRef<Invoices>>
export function getInvoicesRef(shopId: string | undefined) {
  return shopId ? getConvertedDocument<Invoices>(`restaurants/${shopId}/data/invoices`) : undefined
}

// ts-prune-ignore-next
export function getHistoricOrdersRef(shopId: string): CollectionReference<WithRef<HistoricOrders>>
export function getHistoricOrdersRef(
  shopId: string | undefined
): undefined | CollectionReference<WithRef<HistoricOrders>>
export function getHistoricOrdersRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<HistoricOrders>(`restaurants/${shopId}/historicOrders`) : undefined
}

// ts-prune-ignore-next
export function getB2BInvoicesRef(shopId: string): CollectionReference<WithRef<B2BInvoice>>
export function getB2BInvoicesRef(shopId: string | undefined): undefined | CollectionReference<WithRef<B2BInvoice>>
export function getB2BInvoicesRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<B2BInvoice>(`restaurants/${shopId}/b2bInvoices`) : undefined
}

// ts-prune-ignore-next
export function getShopRef(shopId: string): DocumentReference<WithRef<Shop>>
export function getShopRef(shopId: string | undefined): undefined | DocumentReference<WithRef<Shop>>
export function getShopRef(shopId: string | undefined): undefined | DocumentReference<WithRef<Shop>> {
  return shopId ? getConvertedDocument<Shop>(`restaurants/${shopId}`) : undefined
}

// ts-prune-ignore-next
export function getShopStatRef(shopId: string): DocumentReference<WithRef<ShopStat>>
export function getShopStatRef(shopId: string | undefined): undefined | DocumentReference<WithRef<ShopStat>>
export function getShopStatRef(shopId: string | undefined): undefined | DocumentReference<WithRef<ShopStat>> {
  return shopId ? getConvertedDocument<ShopStat>(`restaurants/${shopId}/data/stats`) : undefined
}

// ts-prune-ignore-next
export function getSuccessRef(shopId: string): DocumentReference<WithRef<Success>>
export function getSuccessRef(shopId: string | undefined): undefined | DocumentReference<WithRef<Success>>
export function getSuccessRef(shopId: string | undefined) {
  return shopId ? getConvertedDocument<Success>(`restaurants/${shopId}/data/success`) : undefined
}

// ts-prune-ignore-next
export function getPaymentSettingsRef(shopId: string): DocumentReference<WithRef<PaymentSettings>>
export function getPaymentSettingsRef(
  shopId: string | undefined
): undefined | DocumentReference<WithRef<PaymentSettings>>
export function getPaymentSettingsRef(shopId: string | undefined) {
  return shopId ? getConvertedDocument<PaymentSettings>(`restaurants/${shopId}/data/paymentSettings`) : undefined
}

// ts-prune-ignore-next
export function getShortUrlsRef() {
  return getConvertedCollection<ShortUrl>(`shorturls`)
}

// ts-prune-ignore-next
export function getShortUrlRef(shortUrlId: string): DocumentReference<WithRef<ShortUrl>>
export function getShortUrlRef(shortUrlId: string | undefined): undefined | DocumentReference<WithRef<ShortUrl>>
export function getShortUrlRef(shortUrlId: string | undefined) {
  return shortUrlId ? getConvertedDocument<ShortUrl>(`shorturls/${shortUrlId}`) : undefined
}

// ts-prune-ignore-next
export function getUsersRef() {
  return getConvertedCollection<User>(`users`)
}

// ts-prune-ignore-next
export function getUserRef(userId: string): DocumentReference<WithRef<User>>
export function getUserRef(userId: string | undefined): undefined | DocumentReference<WithRef<User>>
export function getUserRef(userId: string | undefined) {
  return userId ? getConvertedDocument<User>(`users/${userId}`) : undefined
}

// ts-prune-ignore-next
export function getPrivateUserRef(userId: string): DocumentReference<WithRef<PrivateUser>>
export function getPrivateUserRef(userId: string | undefined): undefined | DocumentReference<WithRef<PrivateUser>>
export function getPrivateUserRef(userId: string | undefined) {
  return userId ? getConvertedDocument<PrivateUser>(`users/${userId}/private/user`) : undefined
}

// ts-prune-ignore-next
export function getUserOrdersRef(userId: string): CollectionReference<WithRef<UserOrder>>
export function getUserOrdersRef(userId: string | undefined): undefined | CollectionReference<WithRef<UserOrder>>
export function getUserOrdersRef(userId: string | undefined) {
  return userId ? getConvertedCollection<UserOrder>(`users/${userId}/orders`) : undefined
}

// ts-prune-ignore-next
export function getGlobalStatsRef() {
  return getConvertedDocument<GlobalStats>(`data/stats`)
}

// ts-prune-ignore-next
export function getDiscountCodesRef(shopId: string): CollectionReference<WithRef<DiscountCode>>
export function getDiscountCodesRef(shopId: string | undefined): undefined | CollectionReference<WithRef<DiscountCode>>
export function getDiscountCodesRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<DiscountCode>(`restaurants/${shopId}/discountCodes`) : undefined
}

// ts-prune-ignore-next
export function getDiscountCodeRef(shopId: string, discountCodeId: string): DocumentReference<WithRef<DiscountCode>>
export function getDiscountCodeRef(
  shopId: string | undefined,
  discountCodeId: string | undefined
): undefined | DocumentReference<WithRef<DiscountCode>>
export function getDiscountCodeRef(shopId: string | undefined, discountCodeId: string | undefined) {
  return shopId && discountCodeId
    ? getConvertedDocument<DiscountCode>(`restaurants/${shopId}/discountCodes/${discountCodeId}`)
    : undefined
}

export function getParcelsRef(shopId: string): CollectionReference<WithRef<Parcel>>
export function getParcelsRef(shopId: string | undefined): undefined | CollectionReference<WithRef<Parcel>>
export function getParcelsRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<Parcel>(`restaurants/${shopId}/parcels`) : undefined
}

export function getParcelRef(shopId: string, parcelId: string): DocumentReference<WithRef<Parcel>>
export function getParcelRef(
  shopId: string | undefined,
  parcelId: string | undefined
): undefined | DocumentReference<WithRef<Parcel>>
export function getParcelRef(shopId: string | undefined, parcelId: string | undefined) {
  return shopId && parcelId ? getConvertedDocument<Parcel>(`restaurants/${shopId}/parcels/${parcelId}`) : undefined
}

// ts-prune-ignore-next
export function getPenguinLockerLogsRef(shopId: string): CollectionReference<WithRef<TemperatureLog>>
export function getPenguinLockerLogsRef(
  shopId: string | undefined
): undefined | CollectionReference<WithRef<TemperatureLog>>
export function getPenguinLockerLogsRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<TemperatureLog>(`restaurants/${shopId}/penguinLockerLogs`) : undefined
}

// ts-prune-ignore-next
export function getClickAndCollectLogsRef(shopId: string): CollectionReference<WithRef<ClickAndCollectTemperatureLog>>
export function getClickAndCollectLogsRef(
  shopId: string | undefined
): undefined | CollectionReference<WithRef<ClickAndCollectTemperatureLog>>
export function getClickAndCollectLogsRef(shopId: string | undefined) {
  return shopId
    ? getConvertedCollection<ClickAndCollectTemperatureLog>(`restaurants/${shopId}/clickAndCollectLogs`)
    : undefined
}

// ts-prune-ignore-next
export function getPenguinLockerOrdersRef(shopId: string): CollectionReference<WithRef<PenguinLockerOrder>>
export function getPenguinLockerOrdersRef(
  shopId: string | undefined
): undefined | CollectionReference<WithRef<PenguinLockerOrder>>
export function getPenguinLockerOrdersRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<PenguinLockerOrder>(`restaurants/${shopId}/penguinLockerOrders`) : undefined
}

// ts-prune-ignore-next
export function getPenguinLockerOrderRef(
  shopId: string,
  orderId: string
): DocumentReference<WithRef<PenguinLockerOrder>>
export function getPenguinLockerOrderRef(
  shopId: string | undefined,
  orderId: string | undefined
): undefined | DocumentReference<WithRef<PenguinLockerOrder>>
export function getPenguinLockerOrderRef(shopId: string | undefined, orderId: string | undefined) {
  return shopId && orderId
    ? getConvertedDocument<PenguinLockerOrder>(`restaurants/${shopId}/penguinLockerOrders/${orderId}`)
    : undefined
}

// ts-prune-ignore-next
export function getMintOrdersRef(shopId: string): CollectionReference<WithRef<MintOrder>>
export function getMintOrdersRef(shopId: string | undefined): undefined | CollectionReference<WithRef<MintOrder>>
export function getMintOrdersRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<MintOrder>(`restaurants/${shopId}/mintOrders`) : undefined
}

// ts-prune-ignore-next
export function getMintOrderRef(shopId: string, orderId: string): DocumentReference<WithRef<MintOrder>>
export function getMintOrderRef(
  shopId: string | undefined,
  orderId: string | undefined
): undefined | DocumentReference<WithRef<MintOrder>>
export function getMintOrderRef(shopId: string | undefined, orderId: string | undefined) {
  return shopId && orderId ? getConvertedDocument<MintOrder>(`restaurants/${shopId}/mintOrders/${orderId}`) : undefined
}

export function getOrderPlansRef(shopId: string): CollectionReference<WithRef<OrderPlan>>
export function getOrderPlansRef(shopId: string | undefined): undefined | CollectionReference<WithRef<OrderPlan>>
export function getOrderPlansRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<OrderPlan>(`restaurants/${shopId}/orderPlans`) : undefined
}

export function getOrderPlanRef(shopId: string, orderPlanId: string): DocumentReference<WithRef<OrderPlan>>
export function getOrderPlanRef(
  shopId: string | undefined,
  orderPlanId: string | undefined
): undefined | DocumentReference<WithRef<OrderPlan>>
export function getOrderPlanRef(shopId: string | undefined, orderPlanId: string | undefined) {
  return shopId && orderPlanId
    ? getConvertedDocument<OrderPlan>(`restaurants/${shopId}/orderPlans/${orderPlanId}`)
    : undefined
}
