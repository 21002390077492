import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics"
import { getApps } from "firebase/app"
import { connectAuthEmulator, getAuth } from "firebase/auth"
import firebase from "firebase/compat/app"
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from "firebase/firestore"
import { connectFunctionsEmulator, getFunctions } from "firebase/functions"
import { connectStorageEmulator, getStorage } from "firebase/storage"
import "fix-date"
import { useUser } from "hooks/firestore/simple/useUser"
import { useCurrentUser } from "hooks/localstate/auth/useCurrentUser"
import { useInitSentry } from "hooks/misc/useInitSentry"
import { useRouter } from "next/router"
import posthog from "posthog-js"
import { useEffect } from "react"
import { secrets } from "secrets"

if (!firebase.apps.length) {
  try {
    // initializeApp(secrets.firebase)
    firebase.initializeApp(secrets.firebase)

    // Longpolling may fix https://sentry.io/organizations/getpacked/issues/2750343007/?project=5185239
    const firestoreSettings = { ignoreUndefinedProperties: true, experimentalForceLongPolling: true }
    initializeFirestore(getApps()[0], firestoreSettings)
  } catch (error) {
    console.log(error)
  }

  if (secrets.emulator?.host && process.env.NEXT_PUBLIC_USE_FIRESTORE_EMULATOR === "true") {
    if (secrets.emulator.firestorePort) {
      connectFirestoreEmulator(getFirestore(), secrets.emulator.host, secrets.emulator.firestorePort)
    }
    if (secrets.emulator.authPort) {
      connectAuthEmulator(getAuth(), "http://" + secrets.emulator.host + ":" + secrets.emulator.authPort)
    }
    if (secrets.emulator.functionsPort) {
      connectFunctionsEmulator(getFunctions(), secrets.emulator.host, secrets.emulator.functionsPort)
      connectFunctionsEmulator(
        getFunctions(getApps()[0], "europe-west1"),
        secrets.emulator.host,
        secrets.emulator.functionsPort
      )
      connectFunctionsEmulator(
        getFunctions(getApps()[0], "europe-west3"),
        secrets.emulator.host,
        secrets.emulator.functionsPort
      )
    }
    if (secrets.emulator.storagePort) {
      connectStorageEmulator(getStorage(), secrets.emulator.host, secrets.emulator.storagePort)
    }
  }
}

export function useInitialize() {
  const router = useRouter()
  const user = useCurrentUser()
  const getpackedUser = useUser()
  useEffect(() => {
    getAnalytics()
  }, [])

  useInitSentry()

  const cookiebotCallback = () => {
    const cookiebotStatisticsEnabled = typeof Cookiebot === "object" && Cookiebot?.consent?.statistics
    if (cookiebotStatisticsEnabled) {
      setAnalyticsCollectionEnabled(getAnalytics(), true)
    } else {
      // Disabling Analytics to comply with Cookie Regulation
      // @ts-expect-error: This is probably correct
      window["ga-disable-" + secrets.firebase.measurementId] = true
      setAnalyticsCollectionEnabled(getAnalytics(), false)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      // @ts-expect-error: Cookiebot callback is correct
      window.CookieConsentCallback_OnAccept = () => {
        cookiebotCallback()
      }
      // @ts-expect-error: Cookiebot callback is correct
      window.CookieConsentCallback_OnDecline = () => {
        cookiebotCallback()
      }
    }
  }, [])

  useEffect(() => {
    cookiebotCallback()
  }, [])

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview")
    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [])

  // Identify posthog user
  useEffect(() => {
    if (posthog && user && getpackedUser) {
      const isMerchant = (getpackedUser.restaurants?.length || 0) > 0

      posthog.identify(user.uid, {
        email: user.email,
        name: getpackedUser.userName,
        isMerchant: isMerchant,
      })
    }
  }, [user, getpackedUser])
}

export const localIntercomAppId = secrets.intercom?.appId

export const localAlgoliaIndexPre = secrets.algolia?.indexPre

export const localAlgoliaVars = { appId: secrets.algolia?.appId }
